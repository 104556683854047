import { Anchor, Flex, Image } from '@urbaninfrastructure/react-ui-kit';
import { useIntl } from 'react-intl';
import { LanguageCode } from '../../core-types';
import { useSystem } from '../../lib/core/useSystem';

const appBadgesPath = '/static/assets/app-badges';
const rootIOSBadgePath = `${appBadgesPath}/ios`;
const rootAndroidBadgePath = `${appBadgesPath}/android`;
const badges: {
  ios: Record<LanguageCode, string>;
  android: Record<LanguageCode, string>;
} = {
  // Download App Store badges from https://developer.apple.com/app-store/marketing/guidelines/#section-badges
  ios: {
    en: `${rootIOSBadgePath}/en.svg`,
    nb: `${rootIOSBadgePath}/nb.svg`,
    fr: `${rootIOSBadgePath}/fr.svg`,
    it: `${rootIOSBadgePath}/it.svg`,
    de: `${rootIOSBadgePath}/de.svg`,
    pl: `${rootIOSBadgePath}/pl.svg`,
    uk: `${rootIOSBadgePath}/uk.svg`,
    sv: `${rootIOSBadgePath}/sv.svg`,
    // TODO: swap with es.svg
    es: `${rootIOSBadgePath}/en.svg`,
    fi: `${rootIOSBadgePath}/en.svg`
  },
  // download Play Store badges from https://play.google.com/intl/en_us/badges/
  android: {
    en: `${rootAndroidBadgePath}/en.png`,
    nb: `${rootAndroidBadgePath}/nb.png`,
    fr: `${rootAndroidBadgePath}/fr.png`,
    it: `${rootAndroidBadgePath}/it.png`,
    de: `${rootAndroidBadgePath}/de.png`,
    pl: `${rootAndroidBadgePath}/pl.png`,
    uk: `${rootAndroidBadgePath}/uk.png`,
    sv: `${rootAndroidBadgePath}/sv.png`,
    // TODO: swap with es.svg
    es: `${rootAndroidBadgePath}/en.png`,
    fi: `${rootAndroidBadgePath}/en.png`
  }
};
const anchorProps = {
  display: 'block'
};

const AppBadges = () => {
  const { locale } = useIntl();
  const { androidAppUrl, iosAppUrl } = useSystem();
  return (
    <Flex
      justifyContent={'center'}
      alignItems="center"
      flexDirection={{ _: 'column', sm: 'row' }}
    >
      {androidAppUrl && (
        <Anchor href={androidAppUrl} {...anchorProps}>
          <Image
            src={badges.android[locale]}
            alt="Download android app"
            width="150"
            height="58"
          />
        </Anchor>
      )}
      {iosAppUrl && (
        <Anchor href={iosAppUrl} {...anchorProps} p={2}>
          <Image
            src={badges.ios[locale]}
            alt="Download iOS app"
            width="120"
            height="40"
          />
        </Anchor>
      )}
    </Flex>
  );
};

export default AppBadges;
