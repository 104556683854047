import { useSettingsQuery } from '../lib/core/SettingsQuery';
import redirect from '../lib/redirect';
import {
  AppLandingPage,
  APP_LANDING_PAGE_QUERY
} from '../modules/AppLandingPage';
import Layout from '../modules/Layout';
import { appLandingPageQuery } from '../core-types';
import { CustomNextPage } from '../lib/types';
import { bootstrap } from '../lib/bootstrap';

const AppLanding: CustomNextPage<{}> = ({ url }) => {
  const { loading, data } = useSettingsQuery();
  const systemName = (data && data.settings.title) || '';

  return (
    <Layout
      title="App"
      url={url}
      loading={loading}
      contentProps={{
        py: 0,
        mb: 0,
        mx: 0,
        minHeight: 'auto'
      }}
      render={() => {
        return <AppLandingPage systemName={systemName} />;
      }}
    />
  );
};

AppLanding.getInitialProps = async ctx => {
  const { data } = await ctx.apolloClient.query<appLandingPageQuery>({
    query: APP_LANDING_PAGE_QUERY
  });

  if (ctx.req) {
    if (data && data.system && data.system.appInfo && data.system.appInfo.url) {
      redirect(ctx, data.system.appInfo.url);
    }
  }
};

export default bootstrap(AppLanding);
