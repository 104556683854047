import React from 'react';
import { gql } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import { useSettingsQuery } from '../../lib/core/SettingsQuery';
import { Container, Box, Flex, Video } from '../../components';
import { FeatureDescription } from '../FeatureBlocks';
import PageHeading from '../HowItWorks/PageHeading';
import { videoUrls } from '../../lib/constants';
import AppBadges from './AppBadges';
import { dinamicRenderingConfig } from '../../lib/server/middlewares/system-config/dinamic-rendering';

export const APP_LANDING_PAGE_QUERY = gql`
  query appLandingPageQuery {
    system {
      id
      appInfo {
        os
        url
      }
    }
  }
`;

export const AppLandingPage = ({ systemName }: { systemName: string }) => {
  const settingsQueryProps = useSettingsQuery();
  const system = settingsQueryProps?.data?.system;

  const videoData =
    system?.id && videoUrls.appFindUnlock.sources[system.id]
      ? videoUrls.appFindUnlock.sources[system.id]
      : videoUrls.appFindUnlock.sources['default'];

  const showGetTheAppVideo = dinamicRenderingConfig.find(
    config => config.systemId === system?.id && config.showGetTheAppVideo
  );

  return (
    <>
      <Container textAlign="center">
        <PageHeading>
          <FormattedMessage
            id="modules.AppLandingPage.heading"
            defaultMessage="Get the app, get a bike"
          />
        </PageHeading>
        <Flex flexWrap="wrap" justifyContent="center">
          <FeatureDescription mb="xs">
            <FormattedMessage
              id="modules.AppLandingPage.subHeading"
              defaultMessage="{systemName} is available for your iPhone or Android device."
              values={{
                systemName
              }}
            />
          </FeatureDescription>
          <Box width={1}>
            <AppBadges />
          </Box>
        </Flex>
      </Container>
      {/* Hide video block for Tampere system */}

      <Box mb={!showGetTheAppVideo ? 60 : 0}>
        {showGetTheAppVideo && <Video {...videoData} />}
      </Box>
    </>
  );
};
